import React, { useContext, useEffect } from "react";
import { useData, useGraph } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { Modal } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Button } from "@fluentui/react-northstar";
import adaEmbed from "@ada-support/embed2";

export function Ada() {
  const { teamsfx } = useContext(TeamsFxContext);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const {
    loading: userInfoLoading,
    data: userInfo,
    error: userInfoError,
  } = useData(async () => {
    if (teamsfx) {
      // Fetch basic user info from the context
      const userInfo = await teamsfx.getUserInfo();
      // console.log("userInfo:", userInfo);
      return userInfo;
    }
  });
  const {
    loading: graphDataLoading,
    error: graphDataError,
    data: graphData,
    reload: graphDataReload,
  } = useGraph(
    async (graph, teamsfx, scope) => {
      // Call graph api directly to get user profile information
      const profile = await graph.api("/me").get();

      // console.log("graphData:", profile);

      // Initialize Graph Toolkit TeamsFx provider
      const provider = new TeamsFxProvider(teamsfx, scope);
      Providers.globalProvider = provider;
      Providers.globalProvider.setState(ProviderState.SignedIn);

      let photoUrl = "";
      try {
        const photo = await graph.api("/me/photo/$value").get();
        photoUrl = URL.createObjectURL(photo);
      } catch {
        // Could not fetch photo from user's profile, return empty string as placeholder.
      }
      return { profile, photoUrl };
    },
    { scope: ["User.Read"], teamsfx: teamsfx }
  );
  useEffect(() => {
    if (userInfoLoading || userInfoError || graphDataLoading || graphDataError)
      return;
    if (!graphData?.profile.id) {
      showModal();
      return;
    }

    hideModal();
    teamsfx?.getCredential().getToken(["User.Read"]).then((accessToken) => {
      adaEmbed.start({
        handle: "tex",
        parentElement: "ada-parent",
        metaFields: {
          msteams_access_token: accessToken?.token
        },
      });
    })
  });
  return (
    <div>
      {(userInfoLoading || userInfoError || graphDataLoading || graphDataError) ? <div>Loading ...</div> : (
      <Modal
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={true}
      >
        <div style={{padding: "15px"}}>
          <h2>Authorize Ada</h2>
          <p>Please click authorize to allow Ada to access your Microsoft Teams profile.</p>
          <Button primary content="Authorize" onClick={graphDataReload} />
        </div>
      </Modal>)}
      <div id="ada-parent"></div>
    </div>
  );
}